import axios from 'axios';
import store from '@/store'
import router from '@/router/index';

const { VUE_APP_API } = process.env;

const axiosInstance = axios.create({
  baseURL: VUE_APP_API,
  timeout: 5000
});

axiosInstance.interceptors.request.use( async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.url === '/login') {
    if (config.headers.common && config.headers.common.Authorization) {
      delete config.headers.common.Authorization;
    }
  }
  await router.isReady();
  const { _rawValue: { meta: { moduloId } }} = router.currentRoute;
  const { method,url } = config;
  try {
    console.log(url)
    
    let peticionesSinValidacion = [
    'v1/login',
    'v1/trazabilidad',
    'v1/perfil',
    'v1/permisos'];
    if (peticionesSinValidacion.includes(url)) {
      return config;
    }
    if (moduloId == 0) {
      return config;
    }

    // let rutasExcluidasModuloExterno = [
    //   { urlPeticion: 'v1/unidad-medida2', type: 'get', moduloName: 'UNIDAD', moduloId: 2},
    // ]
    // if(rutasExcluidasModuloExterno.some(x => x.urlPeticion == url && x.moduloId != moduloId)){
    //   return config;
    // }

    let permisoUsuario = store.getters['permisos/gettersPermisos'];
    if(permisoUsuario.length == 0) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      permisoUsuario = store.getters['permisos/gettersPermisos'];
    }
    const metodoArray = [
        { metodo: 'post', valor: 1},
        { metodo: 'put', valor: 2},
        { metodo: 'delete', valor: 4},
        { metodo: 'get', valor: 5},
    ]
    let permisoId = metodoArray.find(x => x.metodo === method).valor
    let estadoPermiso = permisoUsuario.some( x => x.moduloid == moduloId && x.permisos.some( m => m.permisoid == permisoId && m.activo == 1));
    if(!estadoPermiso) throw new Error('Sin permiso');
  } catch (error) {
    return Promise.reject(error);
  }
  return config; 
});

export default axiosInstance;
