import axios from '@/plugins/axios/axios';

const actions = {
  async getPerfil (context) {
    try {
      const result = await axios.get('v1/perfil')
      context.commit('getPerfil',result.data)
    } catch (error) {
      console.log(error)
    }
  }

}

export default actions
